import React, { useState, useEffect } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { sortBy, get } from 'lodash';
import styled from 'styled-components';
import {
  Tooltip, useFlashMessages, FormGroup,
} from '@tripledotstudios/react-core';

import {
  useLocationQuery, useFormPermissions,
} from '@hooks';
import { ErrorIcon } from '@components/icons';
import Button from '@components/buttons';
import useSegmentsOptions from '@/pages/segmentation/segments/queries';

import { SegmentName, SegmentsSelect } from './SegmentsSelect';
import { infoBadgeItemBg } from '../collection';

const SegmentRow = styled(Row)`
  ${({ selected }) => selected && 'background-color: #d8dfef;'};
  &:not(:last-child) {
    border-bottom: 1px #8c8c8c solid;
  }
`;

const ActionsCol = styled(Col)`
  button:hover {
    svg path {
      fill: white;
    }
    &.delete {
      background-color: ${Button.Delete.Color};
    }
    &.edit {
      background-color: ${Button.Edit.Color};
    }
  }
`;

export default function SegmentsControl({
  onSegmentAdd, onSegmentEdit, onSegmentDelete, formGroupName, fields, renderView,
}) {
  const [currentSegmentKey, setCurrentSegmentKey] = useState();
  const segmentSelectRef = React.createRef();
  const { formState: { errors } } = useFormContext();
  const { error } = useFlashMessages();
  const params = useLocationQuery();
  const { readOnly } = useFormPermissions();

  useEffect(() => {
    const configurationSegmentId = params.get('configurationSegmentId');
    if (configurationSegmentId) {
      setCurrentSegmentKey(fields.find(({ id }) => id === +configurationSegmentId)?.key);
    }
  }, [JSON.stringify(fields.map(({ key }) => key)), params.get('configurationSegmentId')]);

  const { data: segmentOptions, isLoading } = useSegmentsOptions();

  const isSelectedSegment = (segment) => !segment._destroy && (segment.key === currentSegmentKey);
  const handleEditSegment = (segment) => {
    setCurrentSegmentKey(segment.key);
    onSegmentEdit({ segmentId: segment.id });
  };
  const handleAddSegmentButtonClick = () => {
    let segmentId = segmentSelectRef.current.getValue()[0];
    segmentId = segmentId?.value ? Number(segmentId.value) : '';

    const existingSegment = fields.find((obj) => (
      !obj._destroy && (segmentId ? obj.segmentId === segmentId : !obj.segmentId)
    ));

    if (existingSegment) {
      error('Segment was already added');
    } else {
      onSegmentAdd({ segmentId });
      segmentSelectRef.current.setValue(segmentSelectRef.current.props.options[1]);
    }
  };

  const sortedFields = () => {
    const segmentsMap = segmentOptions.reduce((memo, obj) => (
      {
        ...memo,
        [obj.value]: { priority: obj.priority, info: obj.info, name: obj.label },
      }
    ), {});
    const fieldsWithSegments = fields.map((obj, index) => (
      {
        ...obj,
        ...segmentsMap[obj.segmentId],
        originalIndex: index,
      }
    ));

    return sortBy(fieldsWithSegments, [(obj) => -obj.priority || 0]);
  };

  const selectedSegments = fields.filter(({ _destroy }) => !_destroy).map(({ segmentId }) => segmentId);

  return isLoading || (
    <Row>
      <Col xs={12} sm={4} lg={3} className="col-xxxl-2">
        <b>Segments:</b>
        {!readOnly && (
          <div className="d-flex">
            <SegmentsSelect segmentSelectRef={segmentSelectRef} selected={selectedSegments} />
            <Button.Add className="ms-1 me-n2 text-nowrap" onClick={handleAddSegmentButtonClick} />
          </div>
        )}
        {sortedFields().map((segment) => {
          const rowClasses = [];
          if (isSelectedSegment(segment)) rowClasses.push('table-primary');

          const hasError = (get(errors, `${formGroupName}.${segment.originalIndex}`));
          return segment._destroy || (
          <SegmentRow key={segment.key} selected={isSelectedSegment(segment)} className="rounded-1 mt-1">
            <Col xs={8} md={9} lg={10}>
              <span className="d-flex"><SegmentName name={segment.name} /></span>
              <Badge bg="secondary" className="me-1 mb-1 py-1 px-2">
                Priority:
                {' '}
                {segment.priority || (!segment.segmentId && '0')}
              </Badge>
              {segment.info && segment.info.split(',').map((item) => (
                <Badge key={item} className="fss-3 fw-normal me-1 px-15 py-05" bg={infoBadgeItemBg(item)}>{item}</Badge>
              ))}

              {hasError && <Tooltip text="has errors inside"><ErrorIcon className="ms-1" /></Tooltip>}
            </Col>
            <ActionsCol xs={4} md={3} lg={2} className="d-flex justify-content-center align-items-center my-1">
              <Button.Edit className="edit me-1" onClick={() => handleEditSegment(segment)} />
              {!readOnly && (
                <Button.Delete
                  className="delete me-1"
                  title="Remove"
                  onClick={() => onSegmentDelete({ segment, index: segment.originalIndex })}
                />
              )}
            </ActionsCol>
          </SegmentRow>
          );
        })}
      </Col>
      <Col xs={12} sm={8} lg={9} className="col-xxxl-10">
        {fields.map((segment, index) => isSelectedSegment(segment) && (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${segment.key}.${index}`} name={`${formGroupName}.${index}`}>
            {renderView({ segment })}
          </FormGroup>
        ))}
      </Col>
    </Row>
  );
}
