import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { map, has, isArray } from 'lodash';
import { Alert } from '@tripledotstudios/react-core';

import {
  UploadIcon, UploadedIcon, UploadErrorIcon, UploadSkipIcon, UploadedPlusIcon,
  UploadProcessingIcon, ErrorIcon, CheckCircleIcon, SlashCircleIcon,
} from '@components/icons';
import { DropFile } from '@components/collection';
import { buildUploadHandler } from '@services/response_handler';

const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const CheckErrorIcon = styled(ErrorIcon)`
  margin: -0.4rem 0.5rem -0.4rem 0;
`;

const CheckSuccessIcon = styled(CheckCircleIcon)`
  margin: -0.4rem 0.5rem -0.4rem 0;
`;

const CheckInfoIcon = styled(SlashCircleIcon)`
  margin: -0.4rem 0.5rem -0.4rem 0;
`;

const UploadDropzoneWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  ${({ entireScreen }) => (entireScreen ? 'height: calc(100vh - 10.7rem)' : '')};
  cursor: pointer;
  border: 1px dashed #cacaca;

  &.onDragOver {
    background-color: #bebebe;
    border-color: #5e5e5e;
  }
`;

const UploadText = styled.span`
  font-size: 1.3rem;
`;

const UploadTextSecondary = styled.div`
  font-size: 0.9rem;
`;

const CheckDescriptionText = styled.span`
  min-width: 20rem;
`;

const MainTextWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: -0.8rem;
  font-weight: 500;
  font-size: 1.2rem;
`;

const CheckIcon = ({ status }) => (
  <>
    {status === 'success' && <CheckSuccessIcon size={2} />}
    {status === 'warning' && <CheckErrorIcon color="#ffc107" size={2} />}
    {status === 'error' && <CheckErrorIcon size={2} />}
    {status === 'info' && <CheckInfoIcon size={2} />}
  </>
);

const CheckContent = ({ title, text, icon }) => (
  <>
    <Col xs={6} className="d-flex align-items-center justify-content-end">
      {title ? `${title}:` : ''}
    </Col>
    <Col xs={6} className="d-flex align-items-center">
      {icon}
      <CheckDescriptionText>{text}</CheckDescriptionText>
    </Col>
  </>
);

const ChecksDisplay = ({ formValues }) => {
  if (!has(formValues, 'checks')) {
    return null;
  }

  return (
    <Row className="align-middle mb-3">
      {map(formValues.checks, ({ title, text, status }) => {
        const icon = <CheckIcon status={status} />;

        if (isArray(text)) {
          return map(text, (line, idx) => (
            <CheckContent key={`title_${idx}`} title={idx === 0 ? title : null} text={line} icon={icon} />
          ));
        }
        return <CheckContent key={title} title={title} text={text} icon={icon} />;
      })}
    </Row>
  );
};

const UploaderIcon = ({ formValues, uploading }) => {
  if (uploading) {
    return <UploadProcessingIcon size={3} />;
  }
  if (!has(formValues, 'main')) {
    return <UploadIcon size={3} />;
  }
  if (formValues.main.status === 'success') return <UploadedPlusIcon size={3} />;
  if (formValues.main.status === 'warning') return <UploadedIcon size={3} color="#ffc107" />;
  if (formValues.main.status === 'skip') return <UploadSkipIcon size={3} />;
  if (formValues.main.status === 'error') return <UploadErrorIcon size={3} />;
  return null;
};

const UploadDropzone = ({
  formValues, className, uploading, entireScreen,
}) => (
  <UploadDropzoneWrapper className={className} entireScreen={entireScreen}>
    <div className="d-flex justify-content-center align-items-center h-100">
      <div>
        {has(formValues, 'allowSave') && formValues.allowSave && (
          <div className="text-center">
            <Alert variant="info">
              New Asset Bundles are added.
              {' '}
              After submitting, it will be marked as Test only so real players will not receive it.
            </Alert>
          </div>
        )}
        <div className="text-center">
          <UploaderIcon formValues={formValues} uploading={uploading} />
        </div>
        {has(formValues, 'main') && (
          <MainTextWrapper className="text-center">
            {formValues.main.text}
          </MainTextWrapper>
        )}
        <ChecksDisplay formValues={formValues} />
        {uploading && <UploadText>Processing upload...</UploadText>}
        {!uploading
          && (
            <div className="text-center">
              <UploadText>Drop a file or click right here to upload</UploadText>
              <UploadTextSecondary>Only zip-files supported</UploadTextSecondary>
            </div>
          )}
      </div>
    </div>
  </UploadDropzoneWrapper>
);

export default function UploadForm({ formValues, setFormValues, uploadRequest }) {
  const [uploading, setUploading] = useState(false);

  const uploadResponseHandler = buildUploadHandler({
    onResult: ({ data }) => {
      setUploading(false);
      setFormValues(data);
    },
  });
  const onUpload = (values) => uploadRequest(values).then(uploadResponseHandler);
  const entireScreen = !has(formValues, 'main');

  const handleFileChange = (file) => {
    const formData = new FormData();
    setFormValues({});
    setUploading(true);
    formData.append('file', file);
    onUpload(formData);
  };

  const uiRender = (classNames) => (
    <UploadDropzone className={classNames} formValues={formValues} uploading={uploading} entireScreen={entireScreen} />
  );

  return (
    <UploaderContainer>
      <DropFile.Zip
        uiRender={uiRender}
        requiredFiles={['manifest.json']}
        onFile={handleFileChange}
      />

    </UploaderContainer>
  );
}
