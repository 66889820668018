import React, { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Table, EmptyList, infoBadgeItemBg } from '@components/collection';
import { InUse } from '@components';

const nameWithDeleted = ({ row: { original: { deleted, name } } }) => (
  deleted ? (
    <>
      <Badge className="me-1 px-15 py-05" bg="secondary">deleted</Badge>
      {' '}
      {name}
    </>
  ) : name
);

export default function List({ response }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Segment ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        Cell: nameWithDeleted,
      },
      {
        Header: 'Info',
        Cell: ({ row: { original: { info } } }) => (
          info?.split(',')?.map((item) => (
            <Badge key={item} className="me-1 px-15 py-05" bg={infoBadgeItemBg(item)}>{item}</Badge>
          ))
        ),
      },

      InUse.InUseColumn,

      {
        Header: 'Synchronized at (UTC)',
        accessor: 'updatedAt',
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No segments yet." />;
  }

  return (
    <Table columns={columns} data={response.items} />
  );
}
