import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@tripledotstudios/react-core';

import { ArchiveIcon } from '../icons';
import BaseTransparentButton from './BaseTransparentButton';

const StyledButton = styled(BaseTransparentButton)`
  &:hover, &:focus {
    svg path {
      fill: #ab9229;
    }
  }
`;

const ArchiveButton = ({ title = 'Archive', ...props }) => (
  <Tooltip placement="bottom" text={title}>
    <StyledButton {...props}>
      <ArchiveIcon />
    </StyledButton>
  </Tooltip>
);

export default ArchiveButton;
