import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';
import { useFormGroup } from '@tripledotstudios/react-core';

import { CloseIcon } from '@components/icons';
import { useFormPermissions } from '@hooks';
import Label from './Label';
import FloatingLabel from './FloatingLabel';
import Error from './Error';

const ClearButton = ({ readOnly, fullName }) => {
  const { setValue } = useFormContext();

  return (
    <InputGroup.Text type="button" onClick={() => readOnly || setValue(fullName, '', { shouldDirty: true })}>
      <CloseIcon />
    </InputGroup.Text>
  );
};

const BaseTimeInput = ({
  name, fullName, readOnly, ...rest
}) => {
  const { register } = useFormContext();

  return (
    <Form.Control
      type="datetime-local"
      {...register(fullName)}
      {...rest}
      disabled={readOnly}
    />
  );
};

const DateTimeInput = ({ name, disabled, ...rest }) => {
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();

  return (
    <>
      <InputGroup>
        <BaseTimeInput readOnly={disabled || readOnly} fullName={fullName} {...rest} />
        <ClearButton readOnly={disabled || readOnly} fullName={fullName} />
      </InputGroup>
      <Error name={name} />
    </>
  );
};

const LabeledDateTimeInput = ({
  label, name, sizes, tooltipText, ...rest
}) => (
  <Label name={name} label={label} sizes={sizes} tooltipText={tooltipText}>
    <DateTimeInput name={name} {...rest} />
  </Label>
);

const FloatingLabeledDateTimeInput = ({
  label, name, tooltipText, sizes: _sizes, disabled, ...rest
}) => {
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();

  return (
    <>
      <InputGroup className="mb-1 mt-2">
        <FloatingLabel label={label} name={name} className="" tooltipText={tooltipText}>
          <BaseTimeInput readOnly={disabled || readOnly} fullName={fullName} {...rest} />
        </FloatingLabel>
        <ClearButton readOnly={disabled || readOnly} fullName={fullName} />
      </InputGroup>
      <Error name={name} />
    </>
  );
};

export { DateTimeInput, LabeledDateTimeInput, FloatingLabeledDateTimeInput };
