import React from 'react';

import { Form, FormContainer } from '@components';
import { LabeledInput, LabeledCheckbox } from '@components/resource';

export default function TesterDevicesForm(props) {
  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />
        <LabeledInput label="IDFV" name="idfv" />
        <LabeledCheckbox label="Test Access" name="testAccess" />
        <LabeledInput label="Base Host override" name="baseHost" />
      </FormContainer>
    </Form.Resource>
  );
}
