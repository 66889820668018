import { useOptionsQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';

export default function useEventsParameterSetsOptions(typeId) {
  return useOptionsQuery({
    name: `events/parameterSets/${typeId}`,
    request: EventsRoutes.ParameterSets.listOptionsRequest,
    params: { typeId },
    enabled: !!typeId,
  });
}
