import React, { useState } from 'react';
import {
  Card, Col, Row, Table, Button as BsButton,
} from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';
import { has, get } from 'lodash';

import { Form, Button, InUse } from '@components';
import { useAppData } from '@hooks';
import {
  LabeledInput, Input, LabeledTextarea, Label,
} from '@components/resource';
import { ButtonsFooter, Status } from '@components/collection';
import { StyledPaneWrapper, StyledPane } from '@components/SplitPane';
import { InUseCollapse, isInUse } from '@components/in_use/InUse';
import { CreativesRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import UploadForm from './UploadForm';
import { CreativeCard } from './TemplateCards';

const Routes = CreativesRoutes.Templates;
const VersionsRoutes = CreativesRoutes.TemplateVersions;

const CheckSums = ({ fileCrc, manifestCrc }) => (
  <>
    <div className="d-flex justify-content-between">
      Asset:
      <span>{fileCrc}</span>
    </div>
    <div className="d-flex justify-content-between">
      Manifest:
      <span>{manifestCrc}</span>
    </div>
  </>
);

const ColumnContent = ({ item, refetch, enums }) => {
  const versionStatuses = enums['Creatives::TemplateVersionStatuses'];
  const availabilityStates = enums.AvailabilityStates;

  return (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.createdAt}</td>
      <td><Status value={item.status} /></td>
      <td><CheckSums {...item.iosBundle} /></td>
      <td><CheckSums {...item.androidBundle} /></td>
      <td>
        {item.sourceFileUrl && (
          <a className="me-2" href={item.sourceFileUrl} target="_blank" rel="noreferrer">Download file</a>
        )}
      </td>
      <td><InUseCollapse inUse={item.inUse} /></td>
      <td>
        {item.permissions.update && (
          <>
            <Button.Confirm
              size="sm"
              variant="success"
              color={item.status === versionStatuses.ACTIVE ? '#aeaeae' : '#28a7454d'}
              title={item.status === versionStatuses.ACTIVE ? 'Deactivate' : 'Activate'}
              confirmTitle="Are you sure?"
              successText="Status of the template version successfully changed"
              confirmText={'Status will be changed to '
                + `${item.status === versionStatuses.ACTIVE ? 'Inactive' : 'Active'}`}
              requestParams={{
                id: item.id,
                status: item.status === versionStatuses.ACTIVE ? versionStatuses.INACTIVE : versionStatuses.ACTIVE,
              }}
              onSuccess={refetch}
              request={VersionsRoutes.updateRequest}
            />
            <Button.Confirm
              size="sm"
              title={item.availabilityStateLive ? 'Make "Test"' : 'Make "Live"'}
              variant="success"
              color={item.availabilityStateLive ? '#a3e5ff' : 'rgba(40, 167, 69, 0.4)'}
              confirmTitle="Are you sure?"
              successText="Availability state of the template version successfully changed"
              confirmText={`Availability state will be changed to ${item.availabilityStateLive ? 'Test' : 'Live'}`}
              requestParams={{
                id: item.id,
                availabilityState: (item.availabilityStateLive ? availabilityStates.TEST : availabilityStates.LIVE),
              }}
              onSuccess={refetch}
              request={VersionsRoutes.updateRequest}
            />
          </>
        )}
      </td>
    </tr>
  );
};

const VersionsTable = ({ data, refetch }) => {
  const [showMore, setShowMore] = useState(false);
  const maxItemsToShow = 5;

  const { enums } = useAppData();

  const handleShowMoreClick = () => setShowMore(!showMore);

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Created at (UTC)</th>
            <th>Status</th>
            <th><InUse.InLiveColumnHeader /></th>
            <th>iOS checksums</th>
            <th>Android checksums</th>
            <th>Original zip</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((templateVersion, index) => (
            (showMore || index < maxItemsToShow) && (
              <ColumnContent key={templateVersion.id} item={templateVersion} refetch={refetch} enums={enums} />
            )
          ))}
        </tbody>
      </Table>
      {data.length > maxItemsToShow && (
        <BsButton variant="light" size="sm" onClick={handleShowMoreClick}>
          {showMore ? 'Show less' : 'Show more...'}
        </BsButton>
      )}
    </>
  );
};

export default function CreativesTemplateForm({
  formValues,
  setFormValues,
  defaultValues,
  refetch,
  actionName,
  submitRequest,
  uploadRequest,
  displayUploadForm,
}) {
  const { query, push } = useRouter();

  const disableSubmit = actionName === undefined || (has(formValues, 'allowSave') && !formValues.allowSave);
  const record = formValues || defaultValues;

  return (
    <StyledPaneWrapper>
      <StyledPane>
        <Row>
          <Col xs={12} md={has(formValues, 'main') || has(defaultValues, 'previewImageUrl') ? 8 : 12}>
            {displayUploadForm
              && (
                <UploadForm formValues={formValues} setFormValues={setFormValues} uploadRequest={uploadRequest} />
              )}
            {(has(formValues, 'main') || defaultValues) && (
              <Form defaultValues={record}>
                {({ setError }) => {
                  const responseHandler = buildResourceResponseHandler({
                    actionName,
                    setError,
                    onSuccess: ({ data: { id } }) => push(Routes.editPath({ ...query, id })),
                  });
                  const onSubmit = (values) => submitRequest(values).then(responseHandler);
                  return (
                    <Form.Component forceDirty={actionName === 'create' && !disableSubmit} onSubmit={onSubmit}>
                      <Input type="hidden" name="fileId" />
                      <LabeledInput label="Internal Name" readOnly disabled name="internalName" />
                      <Label label="In Use">
                        <InUseCollapse inUse={record?.inUse} />
                      </Label>
                      <LabeledInput label="Type" readOnly disabled name="typeName" />
                      <LabeledInput
                        label="Min Supported Version"
                        readOnly
                        disabled
                        name="minSupportedVersion"

                      />
                      <LabeledTextarea label="Layout" name="layoutText" readOnly rows={7} />
                      {has(defaultValues, 'templateVersionsAttributes') && (
                        <VersionsTable data={defaultValues.templateVersionsAttributes} refetch={refetch} />
                      )}
                    </Form.Component>
                  );
                }}
              </Form>
            )}
          </Col>
          {(has(formValues, 'previewImageUrl') || has(defaultValues, 'previewImageUrl')) && (
            <Col xs={12} md={4}>
              <CreativeCard>
                <Card.Img
                  variant="top"
                  src={get(formValues, 'previewImageUrl') || get(defaultValues, 'previewImageUrl')}
                />
              </CreativeCard>
              {actionName === 'update' && record.permissions?.destroy && (
                <Button.Confirm
                  disabled={isInUse(record?.inUse)}
                  title="Delete Creative"
                  variant="danger"
                  confirmTitle="Are you sure?"
                  confirmText={'Creative Template '
                   + `"${record.internalName}" will be deleted and not available for work. Are you sure?`}
                  requestParams={{ id: record.id }}
                  onSuccess={refetch}
                  request={Routes.deleteRequest}
                />
              )}
            </Col>
          )}
        </Row>
      </StyledPane>
      <ButtonsFooter
        cancelOptions={{ title: actionName === 'update' ? 'Back' : 'Cancel', asLink: true, routes: Routes }}
        submitOptions={{ hide: actionName === 'update', disabled: disableSubmit }}
      />
    </StyledPaneWrapper>
  );
}
