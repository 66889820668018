import { useEventTypes } from '@pages/events/types/queries';
import { useEffect, useState } from 'react';
import { Form } from '@components';
import { ParametersForm } from '@components/dynamic_parameters';
import NestedRecordsList from '@components/NestedRecordsList';
import { findById } from '@services/utils';
import { PageSection, useConfirm, useRouter } from '@tripledotstudios/react-core';

import {
  FloatingLabeledInput, FloatingLabeledSelect, LabeledInput, RegisteredError,
} from '@components/resource';
import { useAppData } from '@hooks';

export default function ParameterForm({ resource, ...rest }) {
  const { enumOptions } = useAppData();
  const { query } = useRouter();
  const { data: types } = useEventTypes(query);
  const [newType, setNewType] = useState(null);
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_EVENT];
  const confirm = useConfirm();

  // We want to reset new type when it's rendered for the first time.
  // Otherwise one of ParametersForm callbacks replaces all values with defaults on every render
  useEffect(() => {
    if (newType) setNewType(null);
  }, [newType?.id]);

  if (!types) return null;

  const typesOptions = types.items.map(({ id, name }) => ({ label: name, value: id }));
  const behaviourOptions = enumOptions['Events::ParameterSetRemainingBehaviours'];
  const isInLive = resource.data.availabilityStateLive;

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch, setValue, getValues }) => {
        const typeId = watch('typeId');
        const type = findById(types, typeId);
        const parameterSets = getValues('parameterSetIterationsAttributes') || [];

        const handleTypeChange = (newTypeId) => {
          setValue(
            'parameterSetIterationsAttributes',
            parameterSets?.map((i) => ({ ...i, _destroy: true, position: -1 })),
          );
          setNewType(findById(types, newTypeId));
        };

        const confirmTypeChange = () => {
          if (!typeId || parameterSets.length === 0) {
            return Promise.resolve();
          }

          return confirm.showConfirmation({
            title: 'Are you sure?',
            body: 'Are you sure you want to change the event type?'
              + ' All positions created in this activity set will be cleared.',
          });
        };

        const iterationContent = () => (
          <>
            <LabeledInput label="Name" name="name" />
            <ParametersForm
              newSourceEntity={newType}
              sourceEntity={type}
              DiffWrapper={null}
              allowedAssetBundlesTypes={assetBundlesTypes}
              entityName="Events Type"
            />
          </>
        );
        const headerContent = ({ item }) => (
          <>
            <b>{`Position ${item.position}`}</b>
            <span className="ms-1">{item.name && `- ${item.name}`}</span>
          </>
        );
        const buildNewItem = () => (
          {
            dynamicParametersAttributes: type.dynamicParameterTemplatesAttributes.map(
              ({ id, defaultValue }) => ({ templateId: id, value: defaultValue }),
            ),
          }
        );
        return (
          <>
            <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
              <FloatingLabeledInput label="Name" name="name" />
              <FloatingLabeledSelect
                label="Behaviour for remaining occurrences"
                name="behaviourForRemainingOccurrences"
                options={behaviourOptions}
              />
              <FloatingLabeledSelect
                tooltipText={isInLive ? "Can't change while In-Live" : ''}
                disabled={isInLive}
                onChange={handleTypeChange}
                onConfirmChange={confirmTypeChange}
                label="Events Type"
                name="typeId"
                options={typesOptions}
              />
            </Form.ControlsLayout>
            <div className="mt-4">
              <PageSection title="Positions">
                <RegisteredError name="iterations" />
                <NestedRecordsList
                  attributesPath="parameterSetIterationsAttributes"
                  buildNewItem={buildNewItem}
                  entityName="Position"
                  bodyContent={iterationContent}
                  headerContent={headerContent}
                  addDisabled={!typeId}
                  removeDisabled={isInLive}
                  reorderDisabled={isInLive}
                />
              </PageSection>
            </div>
          </>
        );
      }}
    </Form.Resource>
  );
}
