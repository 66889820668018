import { Form, InUse } from '@components';
import { FloatingLabeledInput, RegisteredError } from '@components/resource';
import Items from './form/Items';

export default function LeagueForm({ resource, ...props }) {
  return (
    <Form.Resource resource={resource} {...props}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        {resource.data.id && (
          <InUse.AvailabilityStateFormGroup
            floating
            readOnly
            tooltipTextOverride="Availability is inherited from the event or activity set where it's used.
            If it's used by multiple entities, the Live availability will have priority"
          />
        )}
      </Form.ControlsLayout>
      <RegisteredError name="base" />
      <Items />
    </Form.Resource>
  );
}
