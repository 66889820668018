import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray } from '@hooks';
import {
  LabeledInput, Label, LabeledSelect, Input,
} from '@components/resource';
import { InUse } from '@/components';
import useEventsClientLeaderboardsOptions from '../../client_leaderboards/queries';

export default function Items() {
  const { generateName } = useFormGroup();
  const formGroupName = generateName('itemsAttributes');

  const { fields } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });

  const { data: clientLeaderboards, isLoading: isLeaderboardsLoading } = useEventsClientLeaderboardsOptions();

  if (isLeaderboardsLoading) {
    return null;
  }

  const clientLeaderboardsOptions = [
    { label: 'None', value: 0 },
    ...clientLeaderboards,
  ];

  return (
    <>
      <Label label={<span className="h4">Leagues</span>} />
      {fields.map((field, index) => (
        <FormGroup key={[field.key, index].join('_')} name={`${formGroupName}.${index}`}>
          <PageSection title={`${field.tierName}`}>
            <Input hidden name="tierNumber" />
            <LabeledSelect
              label="Client leaderboard"
              name="clientLeaderboardId"
              options={clientLeaderboardsOptions}
              formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
            />
            <LabeledInput
              label="Promotion level"
              name="promotionPosition"
              type="number"
              tooltipText="Defines the required position for a player to be eligible for promotion.
                Can be equal zero, cannot be bigger than demotion, unless demotion level is equal 0"
            />
            <LabeledInput
              label="Demotion level"
              name="demotionPosition"
              type="number"
              tooltipText="Defines the required position for a player to be eligible for demotion.
                Can be equal zero, cannot be smaller than promotion, unless promotion level is equal 0"
            />
          </PageSection>
        </FormGroup>
      ),
      )}
    </>
  );
}
