import { useRouter } from '@tripledotstudios/react-core';

import { Form, FormContainer, InUse } from '@/components';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from '@/components/resource';
import { useQuery } from '@/hooks';
import { LocalisationsRoutes } from '@/pages/routes';
import { toOptions } from '@/services/utils';

export default function ProductLabelsForm(props) {
  const { query } = useRouter();
  const { response: localisationKeys } = useQuery(
    LocalisationsRoutes.Keys.indexRequest, { ...query, deleted: false, withoutPagination: true },
  );

  if (!localisationKeys) return null;

  const localisationKeysOptions = [
    { label: 'No Key', value: '' },
    ...toOptions(localisationKeys.items, {
      transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
        availabilityStateLive,
        availabilityState,
      }),
    }),
  ];

  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />
        <LabeledInput label="Key" name="key" />
        <LabeledSelect
          label="Label Key"
          name="labelKeyId"
          options={localisationKeysOptions}
          formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
        />
        <LabeledCheckbox label="Default" name="default" />
      </FormContainer>
    </Form.Resource>
  );
}
