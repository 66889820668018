import { useCallback, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useCrudlRequests, useListQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import {
  DuplicationModal,
  PageHeader,
  SplitPane,
  Button,
} from '@components';
import { LabeledInput } from '@components/resource';
import { ButtonsFooter } from '@components/collection';
import { hasApplicationPermissions } from '@services/permissions';

import List from './List';
import Form from './Form';
import Filter from './Filter';

const Routes = EventsRoutes.ActivitySets;

export default function Index() {
  const { query } = useRouter();
  const { data: response, refetch } = useListQuery({ request: EventsRoutes.ActivitySets.indexRequest });
  const [resource, setResource] = useState(null);
  const [duplicatedResource, setDuplicatedResource] = useState(null);
  const canCreate = hasApplicationPermissions(['events', 'activity_set', 'write']);

  const {
    handleNew,
    handleEdit,
    handleDelete,
    handleOnCancel,
    handleDiscard,
    handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  const handleDuplicate = useCallback(({ id, name }) => setDuplicatedResource({ id, name: `${name}_copy` }), []);

  return (
    <>
      <PageHeader title="Events Activity Sets">
        {canCreate && <Button.Add title="Add Activity Set" onClick={handleNew} />}
      </PageHeader>
      <Filter />
      <SplitPane paneName="events_activity_sets">
        <SplitPane.Top>
          <List
            response={response}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDelete={handleDelete}
            routes={Routes}
            onDuplicateButtonClick={handleDuplicate}
          />
          <DuplicationModal
            record={duplicatedResource}
            handleClose={() => setDuplicatedResource(null)}
            submitRequest={(values) => Routes.duplicateRequest({ ...query, ...values })}
            onSuccess={({ data }) => { refetch(); setDuplicatedResource(null); handleEdit(data.id); }}
          >
            <LabeledInput label="Name" name="name" />
          </DuplicationModal>
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ onClick: handleOnCancel }}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
}
