import React from 'react';
import { get } from 'lodash';
import { Alert, IconButton, useFormGroup } from '@tripledotstudios/react-core';
import { Badge, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { useCurrentApplication } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { useOccurrencesDetailsQuery } from '../queries';

const OccurrenceDetails = ({ occurrence }) => (
  <div>
    <Badge className="ms-2" bg="primary">
      {`${occurrence.startAt} - ${occurrence.endAt}`}
    </Badge>
  </div>
);

const SetInfo = ({ position, set, routes }) => {
  const applicationId = useCurrentApplication().app_id;

  return (
    <>
      <Link
        to={routes.editPath({
          id: set.id,
          applicationId,
        })}
        target="_blank"
      >
        {set.name}
      </Link>
      {` / ${position}`}
    </>
  );
};

const OccurrencesTable = ({ details, occurrences }) => (
  <Table size="sm">
    <thead>
      <tr>
        <th>Time frame</th>
        {details.parameterSet && <th>Parameter set and position</th>}
        {details.activitySet && <th>Activity set and position</th>}
      </tr>
    </thead>
    <tbody>
      {occurrences.map((occurrence) => (
        <tr key={occurrence.startAt}>
          <td><OccurrenceDetails key={occurrence.startAt} occurrence={occurrence} /></td>
          {details.parameterSet && (
          <td>
            <SetInfo
              set={details.parameterSet}
              position={occurrence.parameterSetIterationPosition}
              routes={EventsRoutes.ParameterSets}
            />
          </td>
          )}
          {details.activitySet && (
          <td>
            <SetInfo
              set={details.activitySet}
              position={occurrence.activitySetIterationPosition}
              routes={EventsRoutes.ActivitySets}
            />
          </td>
          )}
        </tr>
      ))}
    </tbody>
  </Table>
);

export default function OccurrencesDetails() {
  const { getValues } = useFormContext();
  const { generateName } = useFormGroup();
  const detailsPath = generateName('occurrencesDetails');
  const variantIndex = parseInt(detailsPath.split('.')[1], 10);
  const initialDetails = get(getValues(), detailsPath);
  const { data, isLoading, refetch } = useOccurrencesDetailsQuery(variantIndex, getValues());
  const details = data || initialDetails;
  const hasNoSets = !details?.parameterSet && !details?.activitySet;

  return (
    <>
      <div className="d-flex">
        <FontAwesomeIcon icon={faCalendar} size="xl" />
        <div className="ms-2">
          <b>Occurrences Details: </b>
          <b>(times are in UTC)</b>
          {isLoading
            ? <Spinner />
            : details && (
              <div className="ms-2">
                {details.isValid ? (
                  <>
                    <div>{details.displayStatus}</div>
                    {details.isRunning && <div>{`Current occurrence: ${details.currentNumber}`}</div>}
                    {details.isRunning && <div>{`Current occurrence ID: ${details.currentId || '-'}`}</div>}
                    {details.isRunning && (
                      <div>
                        {hasNoSets ? (
                          <>
                            Current occurrence timeframe:
                            <OccurrenceDetails occurrence={details.currentOccurrence} />
                          </>
                        ) : (
                          <>
                            Current occurrence:
                            <OccurrencesTable occurrences={[details.currentOccurrence]} details={details} />
                          </>
                        )}
                      </div>
                    )}
                    {details.remainingOccurrences > -1
                      && <div>{`Remaining occurrences: ${details.remainingOccurrences}`}</div>}
                    {details.nextOccurrences.length > 0 && (
                      <>
                        <div>Next Occurrences:</div>
                        {hasNoSets ? (
                          details.nextOccurrences.map((occurrence) => (
                            <OccurrenceDetails key={occurrence.startAt} occurrence={occurrence} />
                          ))
                        ) : <OccurrencesTable occurrences={details.nextOccurrences} details={details} />}
                      </>
                    )}
                  </>
                ) : 'Invalid configuration'}
              </div>
            )}
          <IconButton.Restore className="mt-2" type="button" onClick={refetch}>Refresh</IconButton.Restore>
        </div>
      </div>
      <Alert variant="warning" className="mt-3">
        <div className="ms-2 d-flex align-items-center">
          <div className="flex-grow-1">
            Changes in the configuration are not automatically calculated. Please use the &#39;Refresh&#39; button to
            see how the changes you made will affect future event occurrences.
          </div>
        </div>
      </Alert>
    </>
  );
}
