import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { RegisteredError } from '@components/resource';
import { Button } from '@components';
import { useExtendedFieldArray, useFormPermissions, useAppData } from '@hooks';
import { useFormContext } from 'react-hook-form';

import ConfigurationObjective from './ConfigurationObjective';

export default function ConfigurationObjectives({
  maxActivities,
  objectiveConfigurationsOptions,
  objectiveConfigurationTypesOptions,
  recurring,
}) {
  const { generateName } = useFormGroup();
  const formGroupName = 'configurationObjectivesAttributes';
  const { readOnly } = useFormPermissions();
  const objectiveTimelinesEnum = useAppData().enums['Events::ConfigurationObjectiveTimelines'];
  const fullFormGroupName = generateName(formGroupName);

  const {
    fields,
    append,
    handleDelete,
  } = useExtendedFieldArray({
    name: fullFormGroupName,
    keyName: 'uiKey',
  });
  const { setValue } = useFormContext();
  const appendObjective = () => append({
    configurationObjectiveSegmentsAttributes: [
      { segmentId: null },
    ],
    timeline: objectiveTimelinesEnum.EVENT_DURATION,
  });
  let objectiveIndex = 0;

  const fieldsLength = fields.reduce((acc, item) => (item._destroy ? acc : acc + 1), 0);
  return (
    <>
      <RegisteredError name="base" />

      {fields.map((item, index) => {
        if (item._destroy) return null;

        objectiveIndex += 1;
        const isLast = objectiveIndex === fieldsLength;

        const removeActivity = () => {
          if (isLast) {
            const previousPersistedIndex = fields.findLastIndex((f) => !f._destroy && f !== item);
            setValue(`${fullFormGroupName}.${previousPersistedIndex}.cooldownBeforeNextActivity`, 0);
          }
          handleDelete(item, index);
        };

        return (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${formGroupName}.${index}`} name={`${formGroupName}.${index}`}>
            <ConfigurationObjective
              title={`Activity ${objectiveIndex}`}
              handleDelete={removeActivity}
              objectiveConfigurationsOptions={objectiveConfigurationsOptions}
              objectiveConfigurationTypesOptions={objectiveConfigurationTypesOptions}
              recurring={recurring}
              isLast={isLast}
            />
          </FormGroup>
        );
      })}
      {readOnly || (
        <Button.Add
          title="Add Activity"
          disabled={maxActivities !== 0 && fieldsLength >= maxActivities}
          className="mt-2"
          onClick={appendObjective}
        />
      )}
    </>
  );
}
