import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import {
  createRoutesFromElements, createBrowserRouter, Outlet, Route, Navigate, RouterProvider,
} from 'react-router-dom';
import {
  UIKit, ProvideAuth, useAuth, ProvideFlashMessages, FlashMessages, getThemeName, ConfirmProvider,
} from '@tripledotstudios/react-core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ProvideAppData } from '@hooks';
import { Sidebar, Topbar } from '@components';
import styled, { ThemeProvider } from 'styled-components';
import classicTheme from '@/themes/classic';

import SignIn from './SignIn';
import LoggedOutPage from './LoggedOutPage';
import NotFound from './NotFound';
import PageTitle from './PageTitle';
import ApiDocumentation from './ApiDocumentation';

import applicationsRoutes from './applications/root';
import playersSearchRoutes from './players/search/root';
import segmentationTriggersRoutes from './segmentation/triggers/root';
import segmentationSegmentsRoutes from './segmentation/segments/root';
import playerServerAudienceGroupsRoutes from './player_server/audience_groups/root';
import paymentsGameProductsRoutes from './payments/game_products/root';
import paymentsCashProductsRoutes from './payments/cash_products/root';
import paymentsSectionsRoutes from './payments/sections/root';
import paymentsPresetsRoutes from './payments/presets/root';
import paymentsProductLabelsRoutes from './payments/product_labels/root';
import paymentsProductImagesRoutes from './payments/product_images/root';
import paymentsStoreProductsRoutes from './payments/store_products/root';
import paymentsCatalogsRoutes from './payments/catalogs/root';
import paymentsGameItemsRoutes from './payments/game_items/root';
import campaignsConfigurationsRoutes from './campaigns/configurations/root';
import campaignsSequentialOffersRoutes from './campaigns/sequential_offers/root';
import abtestingExperimentsRoutes from './abtesting/experiments/root';
import creativesTemplatesRoutes from './creatives/templates/root';
import creativesImagesRoutes from './creatives/images/root';
import piggyBanksRoutes from './piggy_banks/configurations/root';
import appLinksActionsRoutes from './app_links/actions/root';
import appLinksConfigurationsRoutes from './app_links/configurations/root';
import localisationKeysRoutes from './localisations/keys/root';
import eventsObjectiveTemplatesRoutes from './events/objective_templates/root';
import eventsObjectiveConfigurationsRoutes from './events/objective_configurations/root';
import eventsCollectionSetsRoutes from './events/collection_sets/root';
import eventsActivitySetsRoutes from './events/activity_sets/root';
import eventsParameterSetsRoutes from './events/parameter_sets/root';
import eventsRacesRoutes from './events/races/root';
import eventsLeaguesRoutes from './events/leagues/root';
import eventsClientLeaderboardsRoutes from './events/client_leaderboards/root';
import eventsConfigurationsRoutes from './events/configurations/root';
import eventsTypesRoutes from './events/types/root';
import eventsDropProbabilitiesRoutes from './events/drop_probabilities/root';
import eventsGachaChestsRoutes from './events/gacha_chests/root';
import applicationActivityLogsRoutes from './activity_logs/root_app';
import activityLogsRoutes from './activity_logs/root';
import testerDevicesRoutes from './tester_devices/root';
import assetBundlesRoutes from './asset_bundles/root';
import debugRoutes from './debug/root';
import openEntityRoutes from './openEntity';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const StyledContainer = styled(Container)`
  // it is important to have "auto" here to hide scrollbars (with "scroll" value) in the bottom of the page
  overflow: auto;
  padding: 0.75rem;
`;

function AuthenticatedApp() {
  const auth = useAuth();

  const isUserLoggedOut = () => {
    const { location: { href } } = window;
    const isLoggedOutHref = !href.includes('sign_in') || href.includes('session_expired');

    return !auth.user && isLoggedOutHref;
  };

  useEffect(() => {
    if (isUserLoggedOut()) window.location.href = '/auth/sso';
  }, []);

  return auth.user && (
    <ProvideAppData>
      {() => (
        <>
          <Topbar />
          <main>
            <Sidebar />
            <StyledContainer className="container-fluid" as="section">
              <FlashMessages />
              <Outlet />
            </StyledContainer>
          </main>
        </>
      )}
    </ProvideAppData>
  );
}

const THEMES_MAPPING = {
  classic: classicTheme,
};

const router = createBrowserRouter([
  ...createRoutesFromElements(
    <Route>
      <Route exact path="/admin/logged_out" element={<LoggedOutPage />} />
      <Route exact path="/admin/sign_in" element={<SignIn />} />
      <Route element={<AuthenticatedApp />}>
        <Route
          exact
          path="/"
          element={(
            <Navigate to="/admin/sign_in" />
          )}
        />

        <Route
          exact
          path="/admin"
          element={(
            <>
              <PageTitle />
              <NotFound />
            </>
          )}
        />

        {applicationsRoutes()}
        {playersSearchRoutes()}
        {segmentationTriggersRoutes()}
        {segmentationSegmentsRoutes()}
        {playerServerAudienceGroupsRoutes()}
        {paymentsGameProductsRoutes()}
        {paymentsCashProductsRoutes()}
        {paymentsSectionsRoutes()}
        {paymentsPresetsRoutes()}
        {paymentsProductLabelsRoutes()}
        {paymentsProductImagesRoutes()}
        {paymentsStoreProductsRoutes()}
        {paymentsGameItemsRoutes()}
        {paymentsCatalogsRoutes()}
        {campaignsConfigurationsRoutes()}
        {campaignsSequentialOffersRoutes()}
        {abtestingExperimentsRoutes()}
        {creativesTemplatesRoutes()}
        {creativesImagesRoutes()}
        {piggyBanksRoutes()}
        {appLinksActionsRoutes()}
        {appLinksConfigurationsRoutes()}
        {localisationKeysRoutes()}
        {eventsObjectiveTemplatesRoutes()}
        {eventsObjectiveConfigurationsRoutes()}
        {eventsCollectionSetsRoutes()}
        {eventsActivitySetsRoutes()}
        {eventsParameterSetsRoutes()}
        {eventsRacesRoutes()}
        {eventsLeaguesRoutes()}
        {eventsClientLeaderboardsRoutes()}
        {eventsConfigurationsRoutes()}
        {eventsTypesRoutes()}
        {eventsDropProbabilitiesRoutes()}
        {eventsGachaChestsRoutes()}
        {applicationActivityLogsRoutes()}
        {activityLogsRoutes()}
        {testerDevicesRoutes()}
        {assetBundlesRoutes()}
        {debugRoutes()}

        <Route
          exact
          path="/admin/api_documentation"
          element={(
            <>
              <PageTitle text="API Documentation" />
              <ApiDocumentation />
            </>
          )}
        />
        <Route
          exact
          path="/admin/ui-kit"
          element={(
            <>
              <PageTitle text="UI Kit" />
              <UIKit />
            </>
          )}
        />
        <Route
          path="*"
          element={(
            <>
              <PageTitle text="Not found" />
              <NotFound />
            </>
          )}
        />
      </Route>
    </Route>,
  ),
  ...openEntityRoutes,
]);

export default function App() {
  const theme = THEMES_MAPPING[getThemeName()];

  return (
    <ProvideAuth>
      <QueryClientProvider client={queryClient}>
        <ProvideFlashMessages>
          <ThemeProvider theme={theme}>
            <ConfirmProvider>
              <RouterProvider router={router} future={{ v7_startTransition: true }} />
            </ConfirmProvider>
          </ThemeProvider>
        </ProvideFlashMessages>
      </QueryClientProvider>
    </ProvideAuth>
  );
}
