import React, { memo } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormGroup } from '@tripledotstudios/react-core';

import { Form } from '@components';
import { FloatingLabeledSelect } from '@components/resource';
import useSegmentsOptions from '@/pages/segmentation/segments/queries';

import { labelFormat } from './SegmentsSelect';

const SegmentTypeOptions = [{ label: 'All Players', value: 'true' }, { label: 'Specific segments', value: 'false' }];

const SegmentConfigurationSelect = ({ tooltipText }) => {
  const { watch } = useFormContext();

  const { data: segmentOptions, isLoading } = useSegmentsOptions();

  const showSegments = !watch('segmentConfiguration.all') || watch('segmentConfiguration.all') === 'false';

  return (
    <FormGroup name="segmentConfiguration">
      <Form.ControlsLayout>
        <Col>
          <FloatingLabeledSelect
            label="Segment type"
            name="all"
            options={SegmentTypeOptions}
          />
        </Col>
        {showSegments && (
          <Col sm={12} md={12} lg={8} xl={8} xxl={9}>
            {isLoading || (
              <FloatingLabeledSelect
                label="Segments"
                options={segmentOptions}
                formatOptionLabel={labelFormat}
                tooltipText={tooltipText}
                name="ids"
                isMulti
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
              />
            )}
          </Col>
        )}
      </Form.ControlsLayout>
    </FormGroup>
  );
};

export default memo(SegmentConfigurationSelect);
