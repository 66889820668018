import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useFormGroup, FormGroup } from '@tripledotstudios/react-core';
import { sortBy } from 'lodash';

import { Button } from '@components';
import { hasError } from '@components/resource';
import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import useSegmentsOptions from '@/pages/segmentation/segments/queries';

import ObjectiveConfigurationSelect from './ObjectiveConfigurationSelect';
import ObjectiveSegmentModal from './ObjectiveSegmentModal';

export default function ConfigurationObjectiveSegments({
  objectiveConfigurationsOptions,
  objectiveConfigurationTypesOptions,
}) {
  const buildSegmentEventKey = (segmentId) => `segment_${+segmentId}`;
  const [showSegmentsModal, setShowSegmentsModal] = useState(false);
  const [currentSegmentEventKey, setCurrentSegmentEventKey] = useState(buildSegmentEventKey(''));
  const { generateName } = useFormGroup();
  const formPath = 'configurationObjectiveSegmentsAttributes';
  const formGroupName = generateName(formPath);
  const { readOnly } = useFormPermissions();
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const handleCloseSegmentsModal = () => {
    setShowSegmentsModal(false);
  };
  const handleAddSegment = (segmentId) => {
    handleCloseSegmentsModal();
    append({ segmentId });
    setCurrentSegmentEventKey(buildSegmentEventKey(segmentId));
  };
  const handleDeleteSegment = (field) => {
    setCurrentSegmentEventKey(buildSegmentEventKey(''));
    handleDelete(field, field.originalIndex);
  };

  const { data: segmentOptions, isLoading } = useSegmentsOptions();

  const titleName = (segmentId) => {
    const segment = segmentOptions.find(({ value }) => +value === +segmentId);
    if (segment) return segment.label;

    return fields.length > 1 ? 'All Other Users' : 'All Users';
  };

  const sortedFields = () => {
    const segmentsMap = segmentOptions.reduce((memo, obj) => (
      {
        ...memo,
        [obj.value]: {
          priority: obj.priority,
          name: obj.label,
        },
      }
    ), {});
    const fieldsWithSegments = fields.map((obj, index) => (
      {
        ...obj,
        ...segmentsMap[obj.segmentId],
        originalIndex: index,
      }
    ));

    return sortBy(fieldsWithSegments, [(obj) => -obj.priority || 0]);
  };
  const visibleFields = fields.filter(({ _destroy }) => !_destroy);
  const showAddButton = !readOnly && +visibleFields.length < +((segmentOptions || []).length + 1);

  return isLoading || (
    <>
      {showAddButton && (
        <Button.Add
          title="Segment"
          className="position-relative float-end"
          onClick={() => setShowSegmentsModal(true)}
        />
      )}
      <Tabs
        className="mb-1"
        transition={false}
        activeKey={currentSegmentEventKey}
        onSelect={(id) => setCurrentSegmentEventKey(id)}
      >
        {sortedFields().map((field) => {
          const isDefault = !field.segmentId;
          const allowToDelete = !readOnly && !isDefault;
          const fieldFormPath = `${formPath}.${field.originalIndex}`;

          return field._destroy || (
            <Tab
              key={field.originalIndex}
              title={titleName(field.segmentId)}
              eventKey={buildSegmentEventKey(field.segmentId)}
              tabClassName={hasError(fieldFormPath) ? 'has-errors' : ''}
            >
              <FormGroup key={`item.${field.originalIndex}`} name={fieldFormPath}>
                <ObjectiveConfigurationSelect
                  objectiveConfigurationTypesOptions={objectiveConfigurationTypesOptions}
                  objectiveConfigurationsOptions={objectiveConfigurationsOptions}
                  handleDelete={allowToDelete ? () => handleDeleteSegment(field) : null}
                />
              </FormGroup>
            </Tab>
          );
        })}
      </Tabs>
      <ObjectiveSegmentModal
        showModal={showSegmentsModal}
        selectedSegments={visibleFields.map(({ segmentId }) => segmentId)}
        handleClose={handleCloseSegmentsModal}
        handleAddSegment={handleAddSegment}
      />
    </>
  );
}
