import React from 'react';

import { useAppData } from '@hooks';

import { FloatingLabel, Label, Select } from '../resource';

export default function AvailabilityStateFormGroup({
  sizes, disabled = false, readOnly = false, floating = false, tooltipTextOverride,
}) {
  const appData = useAppData();
  const tooltipText = tooltipTextOverride || appData.i18n.tooltips.availability_state;
  const availabilityStateOptions = appData.enumOptions.AvailabilityStates;

  const children = (
    <Select
      name="availabilityState"
      withFloatingLabel={floating}
      options={availabilityStateOptions}
      disabled={disabled || readOnly}
    />
  );
  const labelOptions = {
    label: 'Availability', name: 'availabilityState', tooltipText, children,
  };

  return floating ? <FloatingLabel {...labelOptions} /> : <Label sizes={sizes} {...labelOptions} />;
}
