import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { Button, InUse } from '@/components';
import { Input, Select, Label } from '@/components/resource';
import { useExtendedFieldArray, useFormPermissions } from '@/hooks';

export default function LocalisationKeys({ localisationKeysOptions }) {
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();
  const formGroupName = 'localisationKeysAttributes';
  const {
    fields,
    append,
    handleDelete,
  } = useExtendedFieldArray({
    name: generateName(formGroupName),
    keyName: 'uiKey',
  });

  const appendLocalisationKey = () => append({});

  return (
    <Label label="Localisation Keys" sizes={[3, 9]}>
      {fields.map((item, index) => (
        item._destroy || (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${item.uiKey}.${formGroupName}.${index}`} name={`${formGroupName}.${index}`}>
            <div className="d-flex mb-1">
              <div className="flex-fill w-100 me-1">
                <Input name="key" />
              </div>
              <div className="flex-fill w-100">
                <Select
                  name="localisationKeyId"
                  options={localisationKeysOptions}
                  formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
                />
              </div>
              {readOnly || (
                <Button.Delete
                  className="mt-1"
                  title="Remove"
                  onClick={() => handleDelete(item, index)}
                />
              )}
            </div>
          </FormGroup>
        )
      ))}
      {readOnly || <Button.Add className="mt-2" onClick={appendLocalisationKey} />}
    </Label>
  );
}
