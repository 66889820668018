import React from 'react';
import {
  Table, Row, Col,
} from 'react-bootstrap';

import { useRouter } from '@tripledotstudios/react-core';
import { AccountsRoutes } from '@pages/routes';
import { ToggleAction } from '@components/resource';
import { PageHeader } from '@components';

const Routes = AccountsRoutes.Users;

const TestAccesssRadioControl = ({ testAccess, refetch }) => {
  const { query } = useRouter();
  const handleChange = (value) => Routes.updateRequest({ testAccess: value, ...query }).then(refetch);

  return <ToggleAction value={testAccess} onChange={handleChange} fieldName="test" />;
};

const DebugRadioControl = ({ debug, refetch }) => {
  const { query } = useRouter();
  const handleChange = (value) => Routes.updateRequest({ debug: value, ...query }).then(refetch);

  return <ToggleAction value={debug} onChange={handleChange} fieldName="debug" />;
};

export default function UserView({ data, refetch }) {
  return (
    <>
      <PageHeader title="User" />
      <Row>
        <Col lg={6} xs={12}>
          <Table className="table-bordered table-striped">
            <tbody>
              <tr>
                <td>ID</td>
                <td>{data.id}</td>
              </tr>
              <tr>
                <td>Account ID</td>
                <td>{data.accountId}</td>
              </tr>
              <tr>
                <td>Created At</td>
                <td>{data.createdAt}</td>
              </tr>
              <tr>
                <td>Updated At</td>
                <td>{data.updatedAt}</td>
              </tr>
              <tr>
                <td>Test access</td>
                <td>
                  {/* TODO: */}
                  <TestAccesssRadioControl testAccess={data.testAccess} refetch={refetch} />
                </td>
              </tr>
              <tr>
                <td>Debug</td>
                <td>
                  <DebugRadioControl debug={data.debug} refetch={refetch} />
                </td>
              </tr>
              <tr>
                <td>Anonymised</td>
                <td>
                  {data.anonymised ? 'Yes' : 'No'}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
