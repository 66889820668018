import React, { useImperativeHandle } from 'react';
import { Container, Alert } from 'react-bootstrap';

import { Form, InUse } from '@components';
import {
  FloatingLabeledInput, FloatingLabeledSelect, FloatingLabeledDateTimeInput, RegisteredError,
} from '@components/resource';
import { SegmentConfigurationSelect, WrappedSegmentsSelect } from '@components/segmentation';

import Variants from './Variants';

export default function ExperimentsForm({
  formRef, resource: { actionName, data: defaultValues, ...resourceRest },
  statuses, audiences, onAddVariant, onDiscard, onSuccess,
}) {
  // TODO: replace with statusName from server
  const statusName = statuses.find(({ value }) => +value === +defaultValues.status).label;

  return (
    <Form.Resource
      resource={{ data: { ...defaultValues, statusName }, ...resourceRest }}
      onSuccess={onSuccess}
      onDiscard={onDiscard}
    >
      {({ setValue, getValues }) => {
        useImperativeHandle(formRef, () => ({
          setValue,
          getValues,
        }), []);

        return (
          <Container fluid className="form-container">
            {defaultValues.permanentlyArchiveAt && (
              <Alert variant="warning">
                Experiment will be permanently archived after
                {' '}
                {defaultValues.permanentlyArchiveAt}
              </Alert>
            )}
            <Form.ControlsLayout>
              <FloatingLabeledInput label="Name" name="name" />
              <FloatingLabeledInput label="Priority" name="priority" />
              <FloatingLabeledInput label="Status" name="statusName" disabled />
              <FloatingLabeledSelect label="Audience" name="audienceType" options={audiences} />
              <FloatingLabeledDateTimeInput label="Start At" name="startAt" />
              <FloatingLabeledDateTimeInput label="End At" name="endAt" />
              <InUse.AvailabilityStateFormGroup floating />
            </Form.ControlsLayout>
            <WrappedSegmentsSelect>
              <SegmentConfigurationSelect />
            </WrappedSegmentsSelect>
            <RegisteredError name="base" />
            <Variants actionName={actionName} onAddVariant={onAddVariant} />
          </Container>
        );
      }}
    </Form.Resource>
  );
}
