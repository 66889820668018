import { useOptionsQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';

export default function useEventsActivitySetsOptions(typeId) {
  return useOptionsQuery({
    name: `events/activitySets/${typeId}`,
    request: EventsRoutes.ActivitySets.listOptionsRequest,
    params: { typeId },
    enabled: !!typeId,
  });
}
