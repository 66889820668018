import React, { useMemo } from 'react';
import { useAppData } from '@hooks';
import { Table, EmptyList, Status } from '@components/collection';
import { Button, InUse } from '@components';
import { hasApplicationPermissions } from '@services/permissions';
import getMap from '@services/getMap';
import { EventsRoutes } from '@pages/routes';

const Routes = EventsRoutes.Configurations;

export default function List({
  response, onEditButtonClick, routes, onDelete, onDuplicateButtonClick, selectedResourceId, query, refetch,
}) {
  const {
    featureToggles: {
      recurring_events_enabled: recurringEventsEnabled,
    },
    enums,
    enumOptions,
  } = useAppData();
  const canCreate = hasApplicationPermissions(['events', 'configuration', 'write']);
  const statusesMap = getMap(enumOptions['Events::ConfigurationStatuses']);
  const statuses = enums['Events::ConfigurationStatuses'];

  const columns = useMemo(
    () => {
      const generalColumns = [
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Priority',
          accessor: 'priority',
        },
        {
          Header: 'Status',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row: { original: { status } } }) => <Status value={statusesMap[status]} />,
        },
        InUse.AvailabilityStateColumn,
        {
          Header: 'Event type',
          accessor: 'typeName',
        },
        {
          Header: 'Start At',
          accessor: 'startAt',
        },
        {
          Header: 'End At',
          accessor: 'endAt',
        },
        {
          Header: 'Updated at (UTC)',
          accessor: 'updatedAt',
        },
        {
          Header: 'Actions',
          customMinWidth: 140,
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({
            row: {
              original: {
                id, name, priority, permissions, status,
              },
            },
          }) => (
            <>
              {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
              {canCreate && (
                <Button.Duplicate
                  className="me-2"
                  onClick={() => onDuplicateButtonClick({ id, name, priority })}
                />
              )}
              {permissions.edit && (status === statuses.ACTIVE || status === statuses.INACTIVE) && (
                <Button.Confirm
                  size="sm"
                  variant="danger"
                  color="#aeaeae"
                  ButtonComponent={Button.Archive}
                  confirmTitle="Are you sure?"
                  successText="Event configuration successfully archived"
                  confirmText={`You are going to archive event ${name}. Do you want to proceed?`}
                  requestParams={{ id, status: statuses.TEMPORARILY_ARCHIVED }}
                  onSuccess={refetch}
                  request={Routes.updateRequest}
                />
              )}
              {permissions.destroy && (
                <Button.DeleteConfirm
                  id={id}
                  routes={routes}
                  onDelete={onDelete}
                  recordName={`Events Configuration "${name}"`}
                />
              )}
            </>
          ),
        },
      ];
      if (recurringEventsEnabled) {
        generalColumns.splice(3, 0, {
          Header: 'Recurrence type',
          accessor: 'recurring',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: ({ row: { original: { recurring } } }) => (recurring ? 'Recurring' : 'One-off'),
        });
      }
      return generalColumns;
    },
    [JSON.stringify(query)],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No configurations yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
