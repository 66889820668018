import React, { useState } from 'react';
import { useRequireAuth } from '@tripledotstudios/react-core';

import { useCrudlRequests, useListQuery } from '@hooks';
import { PlayerServerRoutes } from '@pages/routes';
import { PageHeader, SplitPane } from '@components';
import { ButtonsFooter } from '@components/collection';

import List from './List';
import Form from './Form';

const Routes = PlayerServerRoutes.AudienceGroups;

export default function Index() {
  useRequireAuth();

  const { data, refetch } = useListQuery({ request: Routes.indexRequest });
  const [resource, setResource] = useState(null);

  const {
    handleEdit, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  return (
    <>
      <PageHeader title="Audience Groups" />
      <SplitPane paneName="audience_groups">
        <SplitPane.Top>
          <List
            response={data}
            refetch={refetch}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
          />
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ onClick: handleOnCancel }}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
}
