import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import { Badge } from 'react-bootstrap';
import { Tooltip } from '@tripledotstudios/react-core';
import { selectTheme } from '@components/resource/Select';
import { infoBadgeItemBg } from '@components/collection';
import useSegmentsOptions from '@/pages/segmentation/segments/queries';

const DEFAULT_SEGMENT_NAME = 'All users';
const DEFAULT_SEGMENT_OPTION = { label: DEFAULT_SEGMENT_NAME, value: '' };

export const SegmentName = ({ name, className }) => {
  const spanRef = useRef(null);
  const fits = () => (spanRef.current ? spanRef.current.scrollWidth <= spanRef.current.offsetWidth : false);
  const segmentName = name || DEFAULT_SEGMENT_NAME;
  const content = fits() ? <span className={className} ref={spanRef}>{segmentName}</span> : (
    <Tooltip
      className={`mw-100 text-truncate overflow-x-hidden text-nowrap ${className}`}
      wrapperProps={{ ref: spanRef }}
      text={segmentName}
      placement="bottom"
    >
      {segmentName}
    </Tooltip>
  );
  return content;
};

export const labelFormat = ({ label, info }) => (
  <div className="d-flex">
    <SegmentName name={label} className="flex-grow-1" />
    <div className="ms-1 align-self-center">
      {info && info.split(',').map((item) => (
        <Badge key={item} className="fss-3 fw-normal me-1 px-15 py-05" bg={infoBadgeItemBg(item)}>{item}</Badge>
      ))}
    </div>
  </div>
);

export const SegmentsSelect = ({ segmentSelectRef, selected }) => {
  const selectedValues = selected.map((segmentId) => segmentId || '');

  const { data: originalSegmentOptions, isLoading } = useSegmentsOptions();

  if (isLoading) return null;

  const segmentsOptions = [
    ...originalSegmentOptions.filter(({ value }) => !selectedValues.includes(value)),
    ...(selectedValues.includes(DEFAULT_SEGMENT_OPTION.value) ? [] : [DEFAULT_SEGMENT_OPTION]),
  ];

  return (
    <div className="flex-grow-1 ms-n2">
      <ReactSelect
        menuPlacement="auto"
        menuPosition="fixed"
        selectTheme={selectTheme}
        defaultValue={segmentsOptions[0]}
        formatOptionLabel={labelFormat}
        ref={segmentSelectRef}
        options={segmentsOptions}
      />
    </div>
  );
};
