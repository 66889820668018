import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import { useAppData } from '@hooks';

const LiveBadge = styled(Badge)`
  background-color: rgba(40, 167, 69, 0.3)!important;
  font-weight: 400;
  font-size: 1rem;
  color: #28a745;
`;

const TestBadge = styled(Badge)`
  background-color: #a3e5ff!important;
  font-weight: 400;
  font-size: 1rem;
  color: #5a8a9d;
`;

export default function InLiveBadge({ inLive, className }) {
  const availabilityStatesEnum = useAppData().localizedEnums.AvailabilityStates;

  return (
    inLive
      ? <LiveBadge className={className}>{availabilityStatesEnum.LIVE}</LiveBadge>
      : <TestBadge className={className}>{availabilityStatesEnum.TEST}</TestBadge>
  );
}
