import { useAppData } from '@hooks';
import useEventsClientLeaderboardsOptions from './client_leaderboards/queries';
import useEventsCollectionSetsOptions from './collection_sets/queries';
import useEventsRacesOptions from './races/queries';
import useEventsObjectiveConfigurationsOptions from './objective_configurations/queries';
import useEventsLeaguesOptions from './leagues/queries';

export default function useObjectiveConfigurationOptions() {
  const { enums } = useAppData();
  const activityTypes = enums['Events::ActivityTypes'];
  const { data: collectionSets, isLoading: isCollectionSetsLoading } = useEventsCollectionSetsOptions();
  const { data: clientLeaderboards, isLoading: isLeaderboardsLoading } = useEventsClientLeaderboardsOptions();
  const { data: objectiveConfigurations, isLoading: isObjectivesLoading } = useEventsObjectiveConfigurationsOptions();
  const { data: races, isLoading: isRacesLoading } = useEventsRacesOptions();
  const { data: leagues, isLoading: isLeaguesLoading } = useEventsLeaguesOptions();

  const configurationOptions = {
    [activityTypes.OBJECTIVE]: objectiveConfigurations,
    [activityTypes.RACE]: races,
    [activityTypes.COLLECTION_SET]: collectionSets,
    [activityTypes.CLIENT_LEADERBOARD]: clientLeaderboards,
    [activityTypes.LEAGUE]: leagues,
  };

  return {
    configurationOptions,
    isLoading: (
      isObjectivesLoading || isCollectionSetsLoading || isRacesLoading || isLeaderboardsLoading || isLeaguesLoading
    ),
  };
}
