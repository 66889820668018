import {
  get, post, put, destroy, patch,
} from '@services/requests';
import { requestsBuilder } from '@tripledotstudios/react-core';
import { camelCase, without } from 'lodash';

const { addAction, resources: baseResources } = requestsBuilder({
  requestsConfiguration: {
    get, post, put, destroy, patch,
  },
});

export const routesMapping = {};
const ignoredModuleSegments = ['', 'admin', ':applicationId', 'applications'];

const resources = (baseUrl, entityName, options = {}) => {
  const routesSet = baseResources(baseUrl, entityName, options);
  let entityKey;
  if (options.entityTypeKey) {
    entityKey = options.entityTypeKey;
  } else {
    const modules = without(baseUrl.split('/'), ...ignoredModuleSegments);
    const entitySegments = entityName.split('/');
    entityKey = camelCase([...modules, ...entitySegments].join());
  }
  routesMapping[entityKey] = routesSet;
  return routesSet;
};

const duplicateAction = (rootPath) => addAction(rootPath, 'duplicate', ':id/duplicate', 'post');
const synchronizeAction = (rootPath) => addAction(rootPath, 'synchronize', 'synchronize', 'post');
const listOptionsAction = (rootPath) => addAction(rootPath, 'listOptions', 'list_options', 'get');

const actionsByName = { duplicate: duplicateAction, synchronize: synchronizeAction, listOptions: listOptionsAction };

const addActions = (...actionNames) => (
  {
    overrides: (rootPath) => (
      actionNames.reduce((result, actionName) => (
        {
          ...result,
          ...actionsByName[actionName](rootPath),
        }
      ), {})
    ),
  }
);

const BaseAppRoute = '/admin/applications/:applicationId';

const UserRoutes = {
  ...addAction('/auth', 'signOut', 'sign_out', 'post', '/auth'),
};

const ApplicationsRoutes = {
  ...resources('/admin', 'applications', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'home', ':applicationId/home', 'get'),
    }),
  }),
};

const ApplicationTokensRoutes = resources(BaseAppRoute, 'application_tokens');

const BaseLocalisationsRoute = `${BaseAppRoute}/localisations`;

const LocalisationsRoutes = {
  Keys: resources(BaseLocalisationsRoute, 'keys', {
    only: ['index'],
    overrides: (rootPath) => ({ ...synchronizeAction(rootPath) }),
  }),
};

const AccountsRoutes = {
  Users: resources(BaseAppRoute, 'users', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'search', 'search', 'post'),
    }),
  }),
  UserCreatives: resources(BaseAppRoute, 'users/:userId/creatives', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'clear', ':id/clear', 'post'),
    }),
  }),
  UserPiggyBanksInstances: resources(BaseAppRoute, 'users/:userId/piggy_banks'),
  UserAbParticipations: resources(BaseAppRoute, 'users/:userId/ab_participations', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'update', '', 'post'),
    }),
  }),
  UserPayments: resources(BaseAppRoute, 'users/:userId/user_payments'),
  UserPurchases: resources(BaseAppRoute, 'users/:userId/user_purchases'),
  UserSegments: resources(BaseAppRoute, 'users/:userId/user_segments', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'clearCache', 'clear_cache', 'post'),
    }),
  }),
  UserEvents: resources(BaseAppRoute, 'users/:userId/events', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'deallocate', ':id/deallocate', 'delete'),
    }),
  }),
  PlayerCards: resources(BaseAppRoute, 'users/:userId/player_cards', {
    only: ['create', 'update'],
  }),
  PlayerPresets: resources(BaseAppRoute, 'users/:userId/player_presets', {
    only: ['index'],
  }),
};

const BaseSegmentationRoute = `${BaseAppRoute}/segmentation`;
const SegmentationRoutes = {
  Triggers: resources(BaseSegmentationRoute, 'triggers'),
  Segments: resources(BaseSegmentationRoute, 'segments', {
    only: ['index'],
    overrides: (rootPath) => ({
      ...synchronizeAction(rootPath),
      ...listOptionsAction(rootPath),
    }),
  }),
};

const BasePlayerServerRoute = `${BaseAppRoute}/player_server`;
const PlayerServerRoutes = {
  AudienceGroups: resources(BasePlayerServerRoute, 'audience_groups'),
};

const BasePaymentsRoute = `${BaseAppRoute}/payments`;
const PaymentsRoutes = {
  Products: {
    ...addAction(`${BasePaymentsRoute}/products`, 'index', null, 'get'),
    ...listOptionsAction(`${BasePaymentsRoute}/products`),
  },
  GameProducts: resources(BasePaymentsRoute, 'game_products'),
  CashProducts: resources(BasePaymentsRoute, 'cash_products'),
  Sections: resources(BasePaymentsRoute, 'sections'),
  Presets: resources(BasePaymentsRoute, 'presets'),
  ProductLabels: resources(BasePaymentsRoute, 'product_labels'),
  ProductImages: resources(BasePaymentsRoute, 'product_images', {
    except: ['create', 'update'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'upload'),
      ...addAction(rootPath, 'update', ':id', 'putUpload'),
    }),
  }),
  StoreProducts: resources(BasePaymentsRoute, 'store_products', { except: ['update'], overrides: listOptionsAction }),
  GameItems: resources(BasePaymentsRoute, 'game_items', {
    except: ['new', 'create', 'delete'],
    overrides: (rootPath) => ({ ...synchronizeAction(rootPath) }),
  }),
  Catalogs: resources(BasePaymentsRoute, 'catalogs', {
    overrides: (rootPath) => ({ ...duplicateAction(rootPath) }),
  }),
};

const BaseCampaignsRoute = `${BaseAppRoute}/campaigns`;
const CampaignsRoutes = {
  Configurations: resources(BaseCampaignsRoute, 'configurations', {
    overrides: (rootPath) => ({ ...duplicateAction(rootPath) }),
  }),
  SequentialOffers: resources(BaseCampaignsRoute, 'sequential_offers', {
    overrides: (rootPath) => ({ ...duplicateAction(rootPath) }),
  }),
};

const SystemRoutes = {
  ...addAction('/admin', 'appData', 'app_data', 'get'),
  ...addAction('/admin', 'swaggerDoc', 'swagger_doc', 'get'),
};

const BaseABTestingRoute = `${BaseAppRoute}/ab_testing`;
const ABTestingRoutes = {
  Experiments: resources(BaseABTestingRoute, 'experiments', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'addEntity', ':id/add_entity', 'post'),
      ...addAction(rootPath, 'removeEntity', ':id/remove_entity', 'post'),
      ...addAction(rootPath, 'addVariant', ':id/add_variant', 'post'),
    }),
  }),
};

const BaseCreativesRoute = `${BaseAppRoute}/creatives`;
const CreativesRoutes = {
  Templates: resources(BaseCreativesRoute, 'templates', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'upload', 'upload', 'upload'),
    }),
  }),
  TemplateVersions: resources(BaseCreativesRoute, 'template_versions'),
  Images: resources(BaseCreativesRoute, 'images', {
    except: ['create', 'update'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'upload'),
      ...addAction(rootPath, 'update', ':id', 'putUpload'),
    }),
  }),
};

const BasePiggyBanksRoutes = `${BaseAppRoute}/piggy_banks`;
const PiggyBanksRoutes = {
  Configurations: resources(BasePiggyBanksRoutes, 'configurations'),
};

const BaseEventsRoute = `${BaseAppRoute}/events`;
const EventsRoutes = {
  ObjectiveTemplates: resources(BaseEventsRoute, 'objective_templates', addActions('duplicate')),
  ObjectiveConfigurations:
    resources(BaseEventsRoute, 'objective_configurations', addActions('duplicate', 'listOptions'),
    ),
  CollectionSets: resources(BaseEventsRoute, 'collection_sets', addActions('duplicate', 'listOptions')),
  Races: resources(BaseEventsRoute, 'races', addActions('duplicate', 'listOptions')),
  ClientLeaderboards: resources(BaseEventsRoute, 'client_leaderboards', addActions('duplicate', 'listOptions')),
  Configurations: resources(BaseEventsRoute, 'configurations', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'occurrencesDetails', 'occurrences_details', 'post'),
    }),
  }),
  Types: resources(BaseEventsRoute, 'types'),
  ParameterSets: resources(BaseEventsRoute, 'parameter_sets', addActions('duplicate', 'listOptions')),
  ActivitySets: resources(BaseEventsRoute, 'activity_sets', addActions('duplicate', 'listOptions')),
  DropProbabilities: resources(BaseEventsRoute, 'drop_probabilities'),
  GachaChests: resources(BaseEventsRoute, 'gacha_chests'),
  Leagues: resources(BaseEventsRoute, 'leagues', addActions('duplicate', 'listOptions')),
};

const BaseApplinksRoute = `${BaseAppRoute}/app_links`;
const AppLinksRoutes = {
  Actions: resources(BaseApplinksRoute, 'actions'),
  Configurations: resources(BaseApplinksRoute, 'configurations'),
};

const ApplicationActivityLogRoutes = resources(BaseAppRoute, 'activity_logs', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'index', 'per_app', 'get'),
  }),
});
const ActivityLogRoutes = resources('/admin', 'activity_logs');

const DebugRoutes = {
  ...addAction('/admin/debug', 'generateJwt', 'generate_jwt', 'post'),
};

const TesterDevicesRoutes = resources('/admin', 'tester_devices');

const AssetBundlesRoutes = resources(BaseAppRoute, 'asset_bundles', {
  only: ['index'],
  overrides: (rootPath) => ({ ...synchronizeAction(rootPath) }),
});

export {
  AccountsRoutes,
  ApplicationsRoutes,
  ApplicationTokensRoutes,
  AssetBundlesRoutes,
  LocalisationsRoutes,
  CampaignsRoutes,
  SegmentationRoutes,
  PlayerServerRoutes,
  PaymentsRoutes,
  UserRoutes,
  SystemRoutes,
  ABTestingRoutes,
  CreativesRoutes,
  PiggyBanksRoutes,
  AppLinksRoutes,
  EventsRoutes,
  ApplicationActivityLogRoutes,
  ActivityLogRoutes,
  DebugRoutes,
  TesterDevicesRoutes,
};
