import { useOptionsQuery } from '@hooks';
import { SegmentationRoutes } from '@pages/routes';

export default function useSegmentsOptions() {
  return useOptionsQuery({
    name: 'segmentation/segments',
    request: SegmentationRoutes.Segments.listOptionsRequest,
  });
}

