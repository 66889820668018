import { matchPath } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';
import { ApplicationsRoutes } from '@pages/routes';

import { useAppData } from './use_app_data';

const useCurrentApplication = () => {
  const { applications } = useAppData();
  const { pathname } = useRouter();

  const isApplicationPage = [
    ApplicationsRoutes.indexRawPath,
    ApplicationsRoutes.newRawPath,
    ApplicationsRoutes.editRawPath,
  ].some((path) => matchPath(pathname, path));

  if (isApplicationPage) return null;

  const match = matchPath('/admin/applications/:applicationId/*', pathname);
  const applicationId = match?.params?.applicationId;

  return match && (applications || []).find((a) => a.app_id === applicationId || a.id === +applicationId);
};

export default useCurrentApplication;
