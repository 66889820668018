import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import Button from '@components/buttons';
import { CheckCircleIcon } from '@components/icons';

export default function List({
  response, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'IDFV',
        accessor: 'idfv',
      },
      {
        Header: 'Test Access',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { testAccess } } }) => (
          testAccess && <CheckCircleIcon />
        ),
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { id, name, permissions } } }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Tester Device "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No configurations yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
