import useObjectiveConfigurationOptions from '@pages/events/useObjectiveConfigurationOptions';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from '@tripledotstudios/react-core';

import { useAppData, useQuery } from '@hooks';
import { findById } from '@services/utils';
import { ExperimentTabs } from '@components/ab_testing';
import { ABTestingRoutes, EventsRoutes } from '@pages/routes';

import ConfigurationVariant from './ConfigurationVariant';

export default function ConfigurationVariants({
  variantsEvents, types, recurring,
}) {
  const { query } = useRouter();
  const { getValues } = useFormContext();
  const [newType, setNewType] = useState(null);
  const { id: eventId, typeId } = getValues();
  const type = findById(types, typeId);
  const { enumOptions } = useAppData();
  const activityTypesOptions = enumOptions['Events::ActivityTypes'];

  const reloadEntityRequest = () => EventsRoutes.Configurations.editRequest({ id: eventId, ...query });

  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { isLoading, configurationOptions } = useObjectiveConfigurationOptions();
  const handleTypeChange = (newTypeId) => setNewType(findById(types, newTypeId));

  useEffect(() => {
    /* eslint-disable no-param-reassign */
    variantsEvents.handleTypeChange = handleTypeChange;
  }, []);

  return experiments?.items && !isLoading && (
    <ExperimentTabs
      entityId={eventId}
      entityType="Events::Configuration"
      experiments={experiments.items}
      reloadEntityRequest={reloadEntityRequest}
      renderView={({
        variantAttributes,
        defaultVariant,
        openBaseView,
        isBaseConfigView,
      }) => (
        <ConfigurationVariant
          configurationOptions={configurationOptions}
          configurationTypesOptions={activityTypesOptions}
          type={type}
          newType={newType}
          defaultVariantAttributes={!variantAttributes.isDefaultVariant && defaultVariant}
          currentAttributes={variantAttributes}
          isBaseConfigView={isBaseConfigView}
          openBaseView={openBaseView}
          recurring={recurring}
        />
      )}
    />
  );
}
